export class ApiService {
    session = null;

    constructor(axios, swal) {
        //this.baseUrl = 'http://localhost:3333';
        this.baseUrl = 'https://api.partyweather.org';
        this.axios = axios;
        this.swal = swal;
    }

    setSession(userName, token) {
        this.session = {
            user: userName,
            authorization: token
        };
    }

    isLogged() {
        return this.session !== null;
    }

    getSession() {
        return this.session;
    }

    logout() {
        this.session = null;
    }

    getGoogleOAuthRedirectUrl() {
        return `${this.baseUrl}/auth/google`;
    }

    async makeAuthGetRequest(url) {
        try {
            const response = await this.axios.get(url, {
                headers: {
                    Authorization: this.session.authorization
                }
            });

            this.session.authorization = response.headers.authorization;

            return response.data;
        } catch (error) {
            console.error(error);
            this.swal.fire({
                icon: 'error',
                title: 'Error en la petición',
                text: `No se ha podido realizar la petición, código de error: ${error.response.status}`
            });
        }

        return null;
    }

    async makeAuthPostRequest(url, data) {
        try {
            const response = await this.axios.post(url, data, {
                headers: {
                    Authorization: this.session.authorization
                }
            });

            this.session.authorization = response.headers.authorization;

            return response.data;
        } catch (error) {
            this.swal.fire({
                icon: 'error',
                title: 'Error en la petición',
                text: `No se ha podido realizar la petición, código de error: ${error.response.status}`
            });
        }

        return null;
    }

    async createAdminUser(email, username) {
        const response = await this.makeAuthPostRequest(`${this.baseUrl}/admin`, {
            email,
            username
        });

        if (response) {
            this.swal.fire({
                icon: 'success',
                title: 'Usuario creado',
                text: 'Se ha creado el usuario correctamente'
            });
        }

        return response;
    }

    async getAdminList() {
        return await this.makeAuthGetRequest(`${this.baseUrl}/admin`);
    }

    async getTemperature(eventId = null) {
        const response = await this.axios.get(eventId ?`${this.baseUrl}/temperature/${eventId}` : `${this.baseUrl}/temperature`);
        return response.data;
    }

    async getConfig() {
        const response = await this.axios.get(`${this.baseUrl}/config`);
        return response.data;
    }

    async getEvents() {
        const response = await this.axios.get(`${this.baseUrl}/events`);
        return response.data;
    }
}